@font-face {
  font-family: hooge;
  src: url(./assets/hooge.ttf);
}

.App {
  text-align: center;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo {
  width: 500px;

  @media (max-width: 580px) {
    width: 350px;
  }
}

.discord-icon {
  padding-right: 20px;
  height: 30px;
}

.discord-link {
  text-decoration: none;
  color: white;
  border: solid 2px #fff;
  padding: 20px;
  margin-top: 50px;
  transition: all 0.25s;
  outline: 2px solid white;
  display: flex;
  align-items: center;
  font-family: hooge, Arial;

  &:hover {
    outline-offset: 10px;
  }
}